import type { IncomingMessage, ServerResponse } from 'http'
import { ApolloClient, HttpLink, from } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { mergeTypeDefs } from '@graphql-tools/merge'
import { parse as cookieParse, serialize as cookieSerialize } from 'cookie'
import fetch from 'cross-fetch'
import { sha256 } from 'crypto-hash'
import { useMemo } from 'react'
import mainSchema from '~/src/__generated__/schema.graphql'
import {
  AUTH_TOKEN_COOKIE_KEY,
  AUTH_TOKEN_LIFETIME,
  REFRESH_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_LIFETIME,
} from '~/src/constants'
import { getExpCompare, isTokenValid } from '~/src/service/auth/is-token-valid'
import { refreshTokenQuery } from '~/src/service/auth/refresh-query'
import { getCookiePath, serializeCookieObject } from '~/src/utils/cookies'
import { isSSR } from '~/src/utils/is-server'
import type { ApolloClientMemoryCache } from '../../types/apollo'
import { createCache } from './apollo-cache'
import { createErrorLink } from './apollo-error'
import { getErrorHandlers } from './errors/error-handlers'
import type { CreateApolloClientProps, ErrorHandlers, InitializeApolloProps } from './types'

/**
 * Overridable storage (not cached) for server side instance accessing
 */
let apolloInstance: ApolloClientMemoryCache

/**
 * Should not hold apollo client instance ON SERVER
 */
let apolloClient: ApolloClientMemoryCache

function createApolloClient({
  forwardHeaders,
  errorHandlers = {},
}: CreateApolloClientProps): ApolloClientMemoryCache {
  /**
   * This extra headers exist since Apollo doesn't know how to pass nextjs SSR request header cookies - we should put them manually
   * node-fetch (used on server in Apollo SSR mode) cannot access browser (server, obviously) and nextjs request headers are not auto-forwarded
   *
   * At the moment of writing this issue is Open (that is fine, but still no recommended way):
   * https://github.com/apollographql/apollo-client/issues/5089
   */
  const extraHeaders = forwardHeaders ?? null // Doesn't exist on client side

  return new ApolloClient({
    ssrMode: isSSR,
    cache: createCache(),
    link: from([
      createPersistedQueryLink({ sha256 }), // useGETForHashedQueries: true - DO NOT USE: CDN caches client IDs, thus poses security issue
      createErrorLink(() => apolloInstance, errorHandlers),
      new HttpLink({
        // On the server we address private cluster URL to avoid cloud roundtrips
        uri: isSSR ? process.env.PRIVATE_CLUSTER_GRAPHQL_URL : process.env.NEXT_PUBLIC_GRAPHQL_URL,
        credentials: 'include',
        headers: {
          ...extraHeaders,
        },
        fetch,
      }),
    ]),
    typeDefs: mergeTypeDefs([mainSchema]),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })
}

/**
 * Do not use directly, use useApollo / initializeWithProlongation instead (both guarantee session valid work)
 */
function initializeApollo({
  initialState = null,
  errorHandlers,
  forwardHeaders,
}: InitializeApolloProps): ApolloClientMemoryCache {
  if (!errorHandlers) {
    errorHandlers = getErrorHandlers((error) => {
      // biome-ignore lint/suspicious/noConsole: debug
      console.log('OnError: ', error)
    })
  }

  apolloInstance =
    apolloClient ??
    createApolloClient({
      forwardHeaders,
      errorHandlers,
    })

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloInstance.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    apolloInstance.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (isSSR) {
    return apolloInstance
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = apolloInstance
  }

  return apolloInstance
}

/**
 * Server-side specific function to catch expired session and try prolongation by refresh token
 */
export async function initializeWithProlongation({
  req,
  res,
}: {
  req: IncomingMessage
  res: ServerResponse
}): Promise<{
  apolloInstance: ApolloClientMemoryCache
  isAuth: boolean
}> {
  const cookies = req.headers.cookie

  if (!cookies) {
    return {
      apolloInstance: initializeApollo({
        forwardHeaders: {},
      }),
      isAuth: false,
    }
  }

  const cookiesParsed = cookieParse(cookies)
  const authToken = cookiesParsed[AUTH_TOKEN_COOKIE_KEY]
  const refreshToken = cookiesParsed[REFRESH_TOKEN_COOKIE_KEY]

  apolloInstance = initializeApollo({
    forwardHeaders: {
      Cookie: cookies,
    },
  })

  /**
   * Token is valid, proceed
   */
  if (authToken && isTokenValid(authToken, getExpCompare())) {
    return {
      apolloInstance,
      isAuth: true,
    }
  }

  /**
   * Token was invalid, try to restore session by refresh token
   */
  if (refreshToken) {
    let results = null

    try {
      results = await refreshTokenQuery(apolloInstance)
    } catch (e) {
      // biome-ignore lint/suspicious/noConsole: debug
      console.error(e)
      return {
        apolloInstance,
        isAuth: false,
      }
    }

    if (!results) {
      return {
        apolloInstance,
        isAuth: false,
      }
    }

    const data = results?.data?.refreshToken
    const newAuthToken = data?.accessToken
    const newRefreshToken = data?.refreshToken

    res.setHeader('Set-Cookie', [
      cookieSerialize(REFRESH_TOKEN_COOKIE_KEY, newRefreshToken, {
        httpOnly: true,
        path: getCookiePath(),
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        maxAge: REFRESH_TOKEN_LIFETIME, // 30 days
        secure: process.env.NODE_ENV !== 'development',
      }),
      cookieSerialize(AUTH_TOKEN_COOKIE_KEY, newAuthToken, {
        httpOnly: true,
        path: getCookiePath(),
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        maxAge: AUTH_TOKEN_LIFETIME, // 1 hr
        secure: process.env.NODE_ENV !== 'development',
      }),
    ])

    const newCookies: Record<string, string> = {
      ...cookiesParsed,
      [AUTH_TOKEN_COOKIE_KEY]: newAuthToken,
      [REFRESH_TOKEN_COOKIE_KEY]: newRefreshToken,
    }

    const newCookiesString = serializeCookieObject(newCookies)

    apolloInstance = initializeApollo({
      forwardHeaders: {
        Cookie: newCookiesString,
      },
    })

    return {
      apolloInstance,
      isAuth: true,
    }
  }

  /**
   * No luck, need new login
   */
  return {
    apolloInstance,
    isAuth: false,
  }
}

export function useApollo({
  initialState,
  errorHandlers,
}: {
  initialState: any
  errorHandlers: ErrorHandlers
}): ApolloClientMemoryCache {
  const store = useMemo(
    () =>
      initializeApollo({
        initialState,
        errorHandlers,
      }),
    [initialState, errorHandlers],
  )

  return store
}
