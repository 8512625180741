import type { OperateModeContext } from '~/src/context'
import type { LevelFieldExtended, LevelOptions } from '~/src/types/entities/jackpot/config-params'
import { ConfigGameParamCodes } from './config-game-params'

export type FieldConfigGameParam = {
  name: ConfigGameParamCodes
  label?: string | ((other: LevelOptions) => string)
  visibilityToggle?: boolean
  type?: 'text' | 'number'
  editable?(operateMode: OperateModeContext, levelField?: LevelFieldExtended): boolean
}

export const fieldsConfigGameParam: FieldConfigGameParam[] = [
  {
    name: ConfigGameParamCodes.fundsMode,
    label: 'Funds mode',
    type: 'text',
  },
  {
    name: ConfigGameParamCodes.entriesCount,
    label: 'Entry count',
  },
  {
    name: ConfigGameParamCodes.levelsCount,
    label: 'Levels count',
  },
  {
    name: ConfigGameParamCodes.runtime,
    label: 'Runtime',
    type: 'text',
  },
  {
    name: ConfigGameParamCodes.rng,
    label: 'RNG',
    type: 'text',
  },
  {
    name: ConfigGameParamCodes.participationCostCoin,
    label: 'Participation cost (coin)',
  },
]
