import {
  type CustomTheme,
  type CustomThemeOptions,
  alpha,
  createTheme,
  dialogContentClasses,
  dialogTitleClasses,
  inputBaseClasses,
  outlinedInputClasses,
  selectClasses,
} from '@mui/material'
import { blue, common, green, grey, orange, red } from '@mui/material/colors'
import { Roboto } from 'next/font/google'

const roboto = Roboto({
  variable: '--font-roboto',
  weight: ['300', '400', '500', '700'],
  subsets: ['latin', 'cyrillic'],
})

/**
 * Use that to override all the basic things
 */
const generalOverrides: CustomThemeOptions = {
  typography: {
    /**
     * https://github.com/mui/material-ui/issues/37080
     *
     * See option #2 https://github.com/mui/material-ui/issues/37080#issuecomment-1527878023
     * Option #1 doesn't work: breaks SSR + client hydration
     */
    fontFamily: [roboto.style.fontFamily].join(','),
  },
  palette: {
    primary: blue,
    secondary: {
      light: '#4CD964',
      main: green[500],
      dark: '#3B873E',
      contrastText: common.white,
    },
    warning: {
      light: '#FFB547',
      main: orange[500],
      dark: '#C77700',
      contrastText: common.white,
    },
    error: {
      main: red[500],
      dark: red[900],
    },
    background: {
      bodyContent: '#F4F4F4',
      drawerBackdrop: '#93939399', // 60% opacity
      sectionTitle: '#FBFBFB',
      tableContainer: grey[100],
      tableSelectedRowLighter: 'rgba(33, 150, 243, 0.08)',
      tableSelectedRow: 'rgba(33, 150, 243, 0.24)',
      tableDisabledRow: grey[100],
      table: common.white,
      default: grey[100],
    },
    border: {
      tabsUnderline: grey[400],
      sectionBorder: grey[200],
    },
    text: {
      leftMenuItem: '#6D7885',
      topMenuSecondary: '#9099A3',
      topMenuActive: green[500],
      tablePopoverSelectedHeader: blue[800],
      deepGray: '#646464',
      lightBlack: '#484848',
    },
    table: {
      rowBorder: grey[300],
    },
    img: {
      borderColor: alpha(grey[300], 0.3),
    },
  },
  constants: {
    headerHeight: '72px',
    inputWidthStandard: '220px',
    standardModalHeight: '503px',
    standardModalWidth: '743px',
    confirmModalWidth: '600px',
    leftMenu: {
      width: '128px',
      paddingRight: 1,
    },
    contentBlock: {
      header: {
        height: '36px',
        marginBottom: 2,
      },
    },

    layoutPaddingTopStandard: 4,
    layoutPaddingBottomStandard: 4,
    errorLayoutPaddingTop: 9.25,
  },
}

const defaultTheme: CustomTheme = createTheme(generalOverrides)

/**
 * Use that to override component classes
 */
const themeOverrides: CustomThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: grey[50],
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          '@media (min-width: 0)': {
            minHeight: '40px',
          },
        },
        gutters: {
          '@media (min-width: 0)': {
            padding: defaultTheme.spacing(2),
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '0',
          boxShadow: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: grey[50],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderColor: defaultTheme.palette.table.rowBorder,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottomColor: grey[100],
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation7: {
          boxShadow:
            '0px 4px 5px -2px #6C6C6C33, 0px 7px 10px 1px #6C6C6C24, 0px 2px 16px 1px #6c6c6c1f',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginTop: defaultTheme.spacing(2),
          '&.Mui-expanded': {
            boxShadow: '0px 5px 11px rgba(0, 0, 0, 0.1)',
            marginTop: defaultTheme.spacing(2),
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: defaultTheme.palette.background.sectionTitle,
          color: defaultTheme.palette.text.deepGray,
          paddingLeft: defaultTheme.spacing(3),
          minHeight: '60px',
          '&.Mui-expanded': {
            minHeight: '60px',
          },
        },
        content: {
          '&.Mui-expanded': {
            marginTop: defaultTheme.spacing(1.5),
            marginBottom: defaultTheme.spacing(1.5),
          },
        },
        expandIconWrapper: {
          padding: defaultTheme.spacing(1.5),
          marginRight: `-${defaultTheme.spacing(1.5)}`,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: 'block',
          paddingTop: defaultTheme.spacing(4),
          paddingLeft: defaultTheme.spacing(3),
          paddingBottom: defaultTheme.spacing(4),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: '1.1876em',
          [`&.${inputBaseClasses.disabled}`]: {
            backgroundColor: alpha(defaultTheme.palette.common.black, 0.04),
            '& input, & textarea': {
              color: defaultTheme.palette.common.black,
              WebkitTextFillColor: defaultTheme.palette.common.black,
            },
          },
          '& [readonly]': {
            [`& ${inputBaseClasses.input}`]: {
              color: defaultTheme.palette.common.black,
              cursor: 'default',
              WebkitTextFillColor: defaultTheme.palette.common.black,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          '[readonly] &~fieldset': {
            border: `1px solid ${alpha(defaultTheme.palette.common.black, 0.23)}`,
          },
        },
        select: {
          [`&.${selectClasses.disabled}`]: {
            paddingRight: defaultTheme.spacing(2),
          },
        },
        icon: {
          [`&.${selectClasses.disabled}`]: {
            display: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`&.${outlinedInputClasses.focused}>[readonly]~fieldset`]: {
            border: `1px solid ${alpha(defaultTheme.palette.common.black, 0.23)}`,
          },
          '&:hover>[readonly]~fieldset': {
            border: `1px solid ${alpha(defaultTheme.palette.common.black, 0.23)}`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: alpha(defaultTheme.palette.common.black, 0.54),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          [`& .${dialogTitleClasses.root}+.${dialogContentClasses.root}`]: {
            padding: defaultTheme.spacing(0),
          },
        },
        paperWidthXs: {
          maxWidth: '348px', // https://www.figma.com/design/Rc6JoYr5iAmfsLZ8IjUvUW/JSA%E2%84%A2---wireframes?node-id=3527-343&t=MFqCqCShyPH9JCn7-1
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          color: defaultTheme.palette.text.deepGray,
        },
        gutterBottom: {
          marginBottom: defaultTheme.spacing(1),
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: defaultTheme.palette.background.tableSelectedRow,
          },
          backgroundColor: defaultTheme.palette.common.white,
          borderBottom: `1px solid ${defaultTheme.palette.table.rowBorder}`,
          overflow: 'auto',
          cursor: 'pointer',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.typography.body2.fontSize, // 14px
        },
      },
    },
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: grey[50],
    },
  },
}

export const theme: CustomTheme = createTheme(defaultTheme, themeOverrides)
