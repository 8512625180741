import { format } from 'date-fns'
import { curryN } from 'ramda'
import { DATE_PICKER_FORMAT_EN } from '~/src/constants/dates'
import { formatMaskFromSeconds } from '~/src/utils/date/format-mask'
import {
  ValidationErrorKeyNumberMaxAsString,
  ValidationErrorKeyNumberMinAsString,
} from './constant'

export const notNumberTypeError: any = {
  key: 'common:validations.notType',
  values: { type: 'number' },
}

export const numberDecimalCountError = (decimalsAllowed: number) => ({
  key: 'common:validations.decimalsAllowed',
  values: { decimalsAllowed },
})

export const numberMinError = (minCompare: string | number) => ({
  key: 'common:validations.numberMin',
  values: { min: minCompare },
})

export const numberMaxError = (maxCompare: string | number) => ({
  key: 'common:validations.numberMax',
  values: { max: maxCompare },
})

export const numberMinMaskError = curryN(2, (mask: string, minCompare: number) => ({
  key: ValidationErrorKeyNumberMinAsString,
  values: { min: formatMaskFromSeconds(minCompare, mask) },
}))

export const numberMaxMaskError = curryN(2, (mask: string, maxCompare: number) => ({
  key: ValidationErrorKeyNumberMaxAsString,
  values: { max: formatMaskFromSeconds(maxCompare, mask) },
}))

export const dateMinUTCError = (minCompare: Date) => ({
  key: 'common:validations.dateMoreThan',
  values: { min: format(minCompare, DATE_PICKER_FORMAT_EN) },
})

export const dateMaxUTCError = (maxCompare: Date) => ({
  key: 'common:validations.dateMax',
  values: { max: format(maxCompare, DATE_PICKER_FORMAT_EN) },
})

export const emailError = {
  key: 'common:validations.email',
}

export const activitySpansOverlap: any = {
  key: 'jackpots:scheduler.activitySpans.spanOverlap',
}

export const minLengthIntervalError: any = {
  key: 'jackpots:scheduler.activitySpans.minLength1Second',
}

export const customCurrencyCannotBeZero: any = {
  key: 'currencies:validations.cannotBeZero',
}

export const requiredError: any = {
  key: 'common:validations.required',
}

export const dateMinFinishDateError: any = {
  key: 'jackpots:scheduler.startEndConfiguration.finishDateTooEarlyError',
}

export const clientCanOwnPrivateAccessOnly = (clientName: string) => ({
  key: 'common:validations.clientCanOwnPrivateAccessOnly',
  values: { clientName },
})

export const clientShouldMatch = {
  key: 'configuration:validation.client-should-match',
}
