import type { IncomingMessage, ServerResponse } from 'http'
import type { User } from '~/src/__generated__/graphql'
import { initializeWithProlongation } from '~/src/lib/apollo/apollo-client'
import type { ApolloClientMemoryCache } from '~/src/types/apollo'
import getUserQuery from './load-user.graphql'

export const loadUserServerPreload = async (
  req: IncomingMessage,
  res: ServerResponse,
  apolloClient?: ApolloClientMemoryCache,
): Promise<Partial<User>> => {
  if (!apolloClient) {
    const { apolloInstance, isAuth } = await initializeWithProlongation({
      req,
      res,
    })

    if (!isAuth) {
      return null
    }

    apolloClient = apolloInstance
  }

  const { data, error } = await apolloClient.query({
    query: getUserQuery,
    fetchPolicy: 'network-only',
  })

  if (error) {
    // biome-ignore lint/suspicious/noConsole: debug
    console.log(error)
    return null
  }

  return data?.my
}

export const getUserSessionQuery = getUserQuery
